

























import Vue from 'vue';

export default Vue.extend({
  props: {
    commissionId: {
      type: String,
      required: true,
    },
    businessId: {
      type: String,
      required: false,
      default: null,
    },
    fileId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      confirmDelete: false,
    };
  },
  methods: {
    async deleteFile() {
      const action = this.businessId ? 'business/commission/file/Delete' : 'commission/file/Delete';

      this.loading = true;

      await this.$store.dispatch(action, {
        BUSINESS_ID: this.businessId,
        COMMISSION_ID: this.commissionId,
        FILE_ID: this.fileId,
      }).catch((e) => {
        this.$emit('file-delete-error', e);
      });
    },
  },
});
